/**
 * Created by simon on 2018-12-12.
 */


import _, {truncate}        from 'lodash';
import moment               from 'moment';
import AutoNumeric          from 'autonumeric';
import store                from '../store/index.js';
import {$t}                 from '../plugins/i18n.js';
import getAutoDecimalplaces from './getAutoDecimalplaces.js';
import formatDate           from './helpers/formatDate.js';

const autoNumericOptions = {
	digitGroupSeparator:           ' ',
	decimalCharacter:              ',',
	decimalCharacterAlternative:   '.',
	decimalPlaces:                 2,
	negativePositiveSignPlacement: 'l',
};

const BrukareNamnTypTextMap = {
	BRUKARE: {
		Brukare:             $t('Brukare'),
		Brukaren:            $t('Brukaren'),
		Brukarens:           $t('Brukarens'),
		BrukarePlural:       $t('Brukare'),
		Brukarnummer:        $t('Brukarnummer'),
		Brukarnumret:        $t('Brukarnumret'),
		Brukarlista:         $t('Brukarlista'),
		BrukareAbbreviation: 'Bru',
		Brukarregistret:     $t('Brukarregistret'),
		Brukarkontot:        $t('Brukarkontot'),
	},
	ARBETSLEDARE: {
		Brukare:             $t('Arbetsledare'),
		Brukaren:            $t('Arbetsledaren'),
		Brukarens:           $t('Arbetsledarens'),
		BrukarePlural:       $t('Arbetsledare'),
		Brukarnummer:        $t('Arbetsledarnummer'),
		Brukarnumret:        $t('Arbetsledarnumret'),
		Brukarlista:         $t('Arbetsledarlista'),
		BrukareAbbreviation: 'Arb',
		Brukarregistret:     $t('Arbetsledarregistret'),
		Brukarkontot:        $t('Arbetsledarkontot'),
	},
	KUND: {
		Brukare:             $t('Kund'),
		Brukaren:            $t('Kunden'),
		Brukarens:           $t('Kundens'),
		BrukarePlural:       $t('Kunder'),
		Brukarnummer:        $t('Kundnummer'),
		Brukarnumret:        $t('Kundnumret'),
		Brukarlista:         $t('Kundlista'),
		BrukareAbbreviation: $t('Kund'),
		Brukarregistret:     $t('Kundregistret'),
		Brukarkontot:        $t('Kundkontot'),
	},
};

class Formatter {
	static escape(value) {
		return _.escape(value);
	}

	static decimal(value, options = {}) {
		if('string' === typeof value) {
			const isNegative = value[0] === '-';

			value = value.replaceAll(',', '.').replaceAll(' ', '').replace(/[^\d.]+/, '');
			if(!value.match(/\d/)) {
				value = 0;
			} else if(isNegative) {
				value = -value;
			}
		}

		if(options?.decimalPlaces === 'auto') {
			options.decimalPlaces = getAutoDecimalplaces(value);
		}

		let formattedValue = '0,00';

		try {
			const autoNumericFormat = AutoNumeric.format(value, {...autoNumericOptions, ...options});

			formattedValue = _.isNil(autoNumericFormat) ? formattedValue : autoNumericFormat;
		} catch{}

		if((formattedValue === '0,00' || formattedValue === '0') && options.allowZero !== true) {
			return '';
		}

		return options.unit ? [formattedValue, options.unit].join(' ') : formattedValue;
	}

	static unformatDecimal(value, options = {}) {
		return AutoNumeric.unformat(value, {...autoNumericOptions, ...options});
	}

	static hourDiff(startTime, endTime, startDate, endDate) {
		if(!startTime || !endTime) {
			return 0;
		}

		startTime = startTime.replace(/\D+/g, '');
		endTime   = endTime.replace(/\D+/g, '');

		const sHH = startTime.slice(0, 2);
		const sMM = startTime.slice(2);
		const eHH = endTime.slice(0, 2);
		const eMM = endTime.slice(2);

		const start = moment(startDate || new Date())
			.hour(parseFloat(sHH))
			.minute(parseFloat(sMM));
		const end = moment(endDate || new Date())
			.hour(parseFloat(eHH))
			.minute(parseFloat(eMM));

		return moment.duration(end.diff(start)).asHours();
	}

	static time(value = '') {
		const val              = [...value.replaceAll(':|,|.', '')];
		const length           = val.length;
		const [H1, H2, T1, T2] = val;

		switch (length) {
			case 1: {
				value = `0${H1}:00`;
		
				break;
			}
			case 2: {
				value = `${H1 + H2}:00`;
		
				break;
			}
			case 3: {
				value = `0${H1}:${H2}${T1}`;
		
				break;
			}
			case 4: {
				value = `${H1 + H2}:${T1}${T2}`;
		
				break;
			}
			default: if(length === 5 && !_.includes(value, ':')) {
				value = Formatter.time(value.slice(0, 4));
			} else {
				return value;
			}
		}

		return value;
	}

	static date(value) {
		return formatDate(value);
	}

	static brukareText(item) {
		return `${item.Brukare} - ${[item.FoNamn, item.EfNamn].join(' ')}`;
		// return [item.FoNamn, item.EfNamn].join(' ');
	}

	static brukareTextFromId(Brukare) {
		if(Brukare === '') {
			return '';
		}

		const brukare = _.find(store.state.BrukarLista, {Brukare});

		return brukare ? Formatter.brukareText(brukare) : '<Saknas>';
	}

	static brukarAnsvText(item) {
		return item.Namn;
	}

	static brukarAnsvTextFromId(Sign) {
		if(Sign === '') {
			return '';
		}

		const brukarAnsv = _.find(store.state.BrukarAnsvLista, {Sign});

		return brukarAnsv ? Formatter.brukarAnsvText(brukarAnsv) : '<Saknas>';
	}

	static avdelningText(item) {
		return item.Namn;
	}

	static avdelningTextFromId(Avdelning) {
		if(Avdelning === '') {
			return '';
		}

		const avdelning = _.find(store.state.AvdelningLista, {Avdelning});

		return avdelning ? Formatter.avdelningText(avdelning) : '<Saknas>';
	}

	static employeeText(item, {withAnstNr = true} = {}) {
		const fullName = [item.FoNamn, item.EfNamn].join(' ');

		if(withAnstNr) {
			return `${item.AnstNr} - ${fullName}`;
		}

		return fullName;
	}

	static employeeTextFromId(AnstNr, options) {
		const employee = _.find(store.state.PerLista, {AnstNr});

		return employee ? Formatter.employeeText(employee, options) : '<Saknas>';
	}

	static tidKodText(TidKod) {
		const tidkod = _.find(store.state.TidKoder, {TidKod});

		return tidkod ? tidkod.BenText : '<Saknas>';
	}

	static resEnhetNamn(number) {
		const ftgInfo = store.state.FtgInfo;

		return ftgInfo[`ResEnhet${number}Namn`] || $t(`ResEnh${number}`);
	}

	static resEnhetTextFromValue(id, value) {
		if(value === '') {
			return '';
		}

		const ftgInfo         = store.state.FtgInfo;
		const ResultatEnheter = store.state.ResultatEnheter || {};

		if(ftgInfo[`RegisterResEnh${id}`]) {
			const item = _.find(ResultatEnheter[id], {ResEnh: value});

			if(item) {
				return item.Namn;
			}
		}

		return value;
	}

	static persNr(value = '') {
		value = value.replace(/\D/g, '');

		if(value.length >= 10) {
			if(value.length === 10) {
				const currentYear = (new Date()).getFullYear();
				const inputYear = parseInt(value.slice(0, 2), 10);

				value = inputYear <= parseInt(currentYear.toString().slice(2), 10) ? `${parseInt(currentYear.toString().slice(0, 2))}${value}` : `${parseInt((currentYear - 100).toString().slice(0, 2))}${value}`;
			}

			return [value.slice(0, 8), value.slice(8)].join('-');
		}

		return value;
	}

	static getRollText(roll) {
		if(roll === 'BRU') {
			return Formatter.brukareNamnTypText('Brukare');
		}

		return $t(`Roles.${roll}`) || roll;
	}

	static momentFormat(value, format = 'YYYY-MM-DD') {
		return moment(value).format(format);
	}

	static momentFromNow(value, withoutSuffix = false) {
		return moment(value).fromNow(withoutSuffix);
	}

	static formatPersNrOrgNrTo12(value, {dash = false, serialNr = false} = {}) {
		value = _.replace(value, /\D+/, '');

		if(value.length === 10) {
			if(value.slice(2, 3) < '2') {
				//Skapa sekelsiffra med utångspunkt att ingen är >= 100 år
				const century = Math.trunc(((new Date()).getFullYear() - parseInt(value.slice(0, 2))) / 100);

				value = `${century}${value}`;
			} else {
				value = `16${value}`
			}
		}

		if(dash) {
			value = value.length === 11 && serialNr ? `${value.slice(0, Math.max(0, value.length - 3))}-${value.slice(- 3)}` : `${value.slice(0, Math.max(0, value.length - 4))}-${value.slice(- 4)}`;
		}

		return value;
	}

	static ellipsis(string, length) {
		return truncate(string, {length});
	}

	static brukareNamnTypText(fieldName) {
		const textMap = BrukareNamnTypTextMap[store.state.FtgInfo.BrukareNamnTyp] || BrukareNamnTypTextMap.BRUKARE;

		return textMap[fieldName];
	}
}

export default Formatter;
