/**
 * Created by simon on 2018-11-30.
 */

import _             from 'lodash';
import {$t}          from '../plugins/i18n.js';
import isValidPersNr from '../../shared/isValidPersNr.js';
import isValidOrgnr  from '../../shared/isValidOrgnr.js';
import isValidEmail  from '../../shared/isValidEmail.js';
import store         from '../store/index.js';
import Formatter     from './Formatter.js';
import isValidDate   from './helpers/isValidDate.js';

class Validate {
	static multiple(options, fns = []) {
		for(const fn of fns) {
			const res = fn(options);

			if(res !== true) {
				return res;
			}
		}

		return true;
	}

	static validLogin(options) {
		return Validate.multiple(options, [
			Validate.required,
			Validate.maxLength(50),
		]);
	}

	static validPassword({value}) {
		if(!_.isString(value)) {
			return $t('Validations.PasswordIsRequired');
		}

		if(value.length < 6) {
			return $t('Validations.PasswordMinimumLength');
		}

		return true;
	}

	static required({value, label}, {allowZero = true} = {}) {
		if((_.isEmpty(value) && !_.isNumber(value)) || _.isUndefined(value) || value === '00000000-0000-0000-0000-000000000000' || (value === 0 && !allowZero) || value === '1899-12-30') {
			return label && label.trim() ? $t('Validations.RequiredField', {label}) : $t('Validations.RequiredFieldNoLabel');
		}

		return true;
	}

	static requiredIf(predicate) {
		return ({value, label, context}) => {
			if(predicate(context)) {
				return Validate.required({value, label});
			}

			return true;
		};
	}

	static oneOf(values) {
		return ({value}) => {
			if(value && !_.includes(values, value)) {
				return $t('Validations.InvalidValue');
			}

			return true;
		};
	}

	static requiredIfOtherFieldHasValue(fieldName) {
		return ({value, label, context}) => {
			if(!_.isEmpty(context[fieldName])) {
				return Validate.required({value, label});
			}

			return true;
		};
	}

	static maxLength(length = Infinity) {
		return ({value, label}) => {
			if(_.isString(value) && value.length > length) {
				return $t('Validations.ExceededMaxLength', {label, length});
			}

			return true;
		};
	}

	static minLength(length = 1) {
		return ({value, label}) => {
			if(_.isString(value) && value.length < length) {
				return $t('Validations.ValueLengthTooShort', {label, length});
			}

			return true;
		};
	}

	static validInteger({value, label}) {
		if(value === '00' || _.isNaN(parseInt(value, 10)) || !_.isInteger(_.toNumber(value))) {
			return $t('Validations.InvalidInteger', {label})
		}

		return true;
	}

	static numberBetween(min, max) {
		return ({value, label}) => {
			const val = _.toNumber(Formatter.unformatDecimal(value));

			if(val < min || val > max) {
				return $t('Validations.NumberBetween', {label, min, max});
			}

			return true;
		}
	}

	static numberGreaterThan(min) {
		return ({value, label}) => {
			const val = _.toNumber(Formatter.unformatDecimal(value));

			if(val <= min) {
				return $t('Validations.NumberGreaterThan', {label, min});
			}

			return true;
		}
	}

	static validDate({value}) {
		if(!value) { //Use Validate.required
			return true;
		}

		if(!isValidDate(value, {deferValidationUntilFormatted: true})) {
			return $t('Validations.InvalidDate');
		}

		return true;
	}

	static validTime({value}) {
		if(!value || value.length === 0) { //Use required
			return true;
		}

		//Anything other than 0-9 and :
		if(value.match(/[^\d:]+/g)) {
			return $t('Validations.InvalidTime');
		}

		value = value.replace(/\D+/g, '');

		let HH = value.slice(0, 2);

		let MM = value.slice(2);

		if(!MM) {
			MM = 0;
		}

		HH = parseFloat(HH);
		MM = parseFloat(MM);

		if(HH < 0 || HH > 24 || isNaN(HH) || MM < 0 || MM > 59 || isNaN(MM)) {
			return $t('Validations.InvalidTime');
		}

		return true;
	}

	static validPersNr({value}) {
		if(value && !isValidPersNr(value, {allowSamordningsnumer: true})) {
			return $t('Validations.InvalidPersonalIdentityNumber');
		}
		
		return true;
	}

	static validOrgNr({value}) {
		if(!isValidOrgnr(value)) {
			return $t('Validations.InvalidOrganisationNumber');
		}

		return true;
	}

	static validEmail({value}) {
		if(value && !isValidEmail(value)) {
			return $t('Validations.InvalidEmail');
		}

		return true;
	}

	static checkTimeReportingBrukareRequired({value, label}) {
		try {
			const {FtgInfo, PortalInfo} = store.state;

			if(FtgInfo.UseLSS && PortalInfo.UseMandatoryBrukare && !value) {
				return label && label.trim() ? $t('Validations.RequiredField', {label}) : $t('Validations.RequiredFieldNoLabel')
			}

			return true;
		} catch{
			return true;
		}
	}

	static checkTimeReportingResEnhetRequired(number) {
		return ({value, label}) => {
			try {
				const {FtgInfo, PortalInfo} = store.state;

				if(FtgInfo[`OpenResEnhet${number}`] && FtgInfo[`RegResEnh${number}`] && PortalInfo[`UseMandatoryResEnhet${number}`] && !value) {
					return label && label.trim() ? $t('Validations.RequiredField', {label}) : $t('Validations.RequiredFieldNoLabel')
				}

				return true;
			} catch {
				return true;
			}
		}
	}

	static checkCompensationsReportingReceiptRequired({LonKod, ReceiptId, file}) {
		try {
			const {PortalInfo} = store.state;

			if(PortalInfo.UseMandatoryReceipt && LonKod === 'UTLÄGG' && !file && (!ReceiptId || ReceiptId === '00000000-0000-0000-0000-000000000000')) {
				return $t('Validations.ReceiptRequired');
			}

			return true;
		} catch{
			return true;
		}
	}

	static checkCompensationsReportingBrukareRequired({value, label}) {
		try {
			const {FtgInfo, PortalInfo} = store.state;

			if(FtgInfo.UseLSS && PortalInfo.UseErsMandatoryBrukare && !value) {
				return label && label.trim() ? $t('Validations.RequiredField', {label}) : $t('Validations.RequiredFieldNoLabel')
			}

			return true;
		} catch{
			return true;
		}
	}

	static checkCompensationReportingResEnhetRequired(number) {
		return ({value, label}) => {
			try {
				const {FtgInfo, PortalInfo} = store.state;

				if(FtgInfo[`OpenResEnhet${number}`] && FtgInfo[`RegResEnh${number}`] && PortalInfo[`UseErsMandatoryResEnhet${number}`] && !value) {
					return label && label.trim() ? $t('Validations.RequiredField', {label}) : $t('Validations.RequiredFieldNoLabel')
				}

				return true;
			} catch {
				return true;
			}
		}
	}

	static alphanumeric({label, value}) {
		if(value && value.replace(/[\da-zäåö]/gi, '') !== '') {
			return $t('Validations.MustBeAlphanumeric', {label});
		}

		return true;
	}

	static validBICLength() {
		return ({value, label}) => {
			if(_.isString(value) && value && value.length !== 8 && value.length !== 11) {
				return `${label} måste vara antingen 8 eller 11 tecken långt.`;
			}

			return true;
		};
	}
}

export default Validate;
