<template>
	<v-slide-x-transition hide-on-leave>
		<v-text-field
						v-model="formattedValue"
						:rules="model.$rules[name]"
						v-bind="$attrs"
						v-on="allowedListeners"
						:label="noLabel ? '' : label ? label : model.constructor.propertyMap[name].label"
						@keydown="$emit('keydown', $event)"
						@keyup="$emit('keyup', $event)"
						ref="field"
						validate-on-blur
						:data-name="name"
						:data-tabordername="tabOrderName || name"
						@focus="onFocus($event)"
						@blur="onBlur($event)"
						@keydown.native="$checkForTabOrderEvent($event)"
						:counter="fieldCounter"
						@change="onChange"
						@input="onInput($event)"
						autocomplete="off"
						color="accent"
						persistent-placeholder
		></v-text-field>
	</v-slide-x-transition>
</template>

<script>
import {get, toNumber, noop} from 'lodash';
import getAutoDecimalplaces  from '../../lib/getAutoDecimalplaces.js';

export default {
	name: 'NumberField',

	props: {
		model: {
			required: true,
		},
		name: {
			required: true,
		},
		noLabel: {
			type:    Boolean,
			default: false,
		},
		counter: {
			default: false,
		},
		tabOrderName: {
			type:    String,
			default: '',
		},
		decimalPlaces: {
			default: 2,
		},
		digitGroupSeparator: {
			default: ' ',
		},
		suppressZeros: {
			type:    Boolean,
			default: false,
		},
		label: {
			type:    String,
			default: '',
		},
	},

	mounted() {
		this.setValue(this.model[this.name]);
	},

	data: () => ({
		fieldCounter:        false,
		formattedValue:      0,
		changeRemoteHandler: noop,
	}),

	computed: {
		modelValue() {
			return this.model[this.name];
		},

		allowedListeners() {
			if(this.$listeners.change) {
				this.changeRemoteHandler = this.$listeners.change;
			}

			delete this.$listeners.change;

			return this.$listeners;
		},
	},

	watch: {
		modelValue(newValue) {
			this.setValue(newValue);
		},

		suppressZeros() {
			this.setValue(this.model[this.name]);
		},
	},

	methods: {
		onChange(value) {
			const prevValue = this.model[this.name];
			const cleared = value === '';

			if(value === undefined || value === null || value === '') {
				value = 0;
			}

			this.setValue(value);

			if(prevValue !== this.model[this.name]) {
				this.changeRemoteHandler(this.model[this.name], cleared, prevValue);
			}
		},

		onInput(value) {
			//When resetValidation is called
			if(value === undefined || value === null) {
				this.setValue(0);
			}
		},

		focus() {
			this.$refs.field.focus();
		},

		blur() {
			this.$refs.field.blur();
		},

		setValue(value) {
			let {decimalPlaces} = this;

			if(decimalPlaces === 'auto') {
				decimalPlaces = getAutoDecimalplaces(value);
			}

			this.formattedValue = this.$formatter.decimal(value, {allowZero: !this.suppressZeros, decimalPlaces, digitGroupSeparator: this.digitGroupSeparator});
			this.model[this.name] = toNumber(this.$formatter.unformatDecimal(this.formattedValue, {decimalPlaces}));
		},

		onFocus() {
			this.$eventBus.$emit('Help:SetFromFieldFocus', {
				model: this.model,
				name:  this.name,
			});

			// this.$emit('focus', event);

			this.fieldCounter = this.counter;

			try {
				const $input = get(this.$refs, 'field.$refs.input', null);

				if($input) {
					$input.select();
					$input.setSelectionRange(0, ($input.value || '').length)
				}
			} catch{}
		},

		resetValidation() {
			this.$refs.field.resetValidation();
		},

		onBlur() {
			this.resetValidation();

			// this.$emit('blur', event);

			this.fieldCounter = false;
		},
	},
}
</script>

<style scoped>
	::v-deep(input) {
		text-align: right;
	}
</style>
