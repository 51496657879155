/**
 * Created by simon on 2018-11-30.
 */

import {
	isEmpty,
	forEach,
	isUndefined,
	has,
	isString,
	toNumber,
	isArray,
	pick,
	keys,
	map,
	uniqueId,
	isFunction,
	includes,
	indexOf,
	compact,
	isObject,
	flow,
	isEqual,
	cloneDeep
} from 'lodash';
import isValidDate from '../lib/helpers/isValidDate.js';
import formatDate  from '../lib/helpers/formatDate.js';

class BaseModel {
	constructor(data, {
		extendedRules = {}, //Add additional rules for fields, such as view specific rules
	} = {}) {
		this.$rules         = {};

		this.options = {extendedRules};

		this._id = uniqueId(this.constructor.name);

		this._attributes         = {};
		this._originalAttributes = {};
		this._changedAttributes  = [];

		this.set(data, {clear: true});
	}

	set(data = {}, {clear = false} = {}) {
		const {extendedRules} = this.options;

		const isNew = isEmpty(this._originalAttributes) || clear === true;

		forEach(this.constructor.propertyMap, (config, fieldName) => {
			//Not yet defined, new model, so define prop
			if(!has(this, fieldName)) {
				Object.defineProperty(this, fieldName, {
					get() {
						return this._attributes[fieldName];
					},
					set(value) {
						//New instance, dont preprocesse or set as modified
						if(!has(this._originalAttributes, fieldName)) {
							this._attributes[fieldName] = value;

							return;
						}

						if(isEqual(value, this._originalAttributes[fieldName])) {
							const index = indexOf(this._changedAttributes, fieldName);

							if(index >= 0) {
								this._changedAttributes.splice(index, 1);
							}
						} else if(!includes(this._changedAttributes, fieldName)) {
							//Ugly hack to prevent default dates being set to '' (for display purposes) to be considered a 'change'
							if(this._originalAttributes[fieldName] !== '1899-12-30') {
								this._changedAttributes.push(fieldName);
							}
						}

						this._attributes[fieldName] = flow(config.mutations || [])(value);

						if(config.onChange) {
							config.onChange(this._attributes[fieldName], this);
						}
					},
				});
			}

			// eslint-disable-next-line no-nested-ternary
			let value = has(data, fieldName) ? data[fieldName] : (clear ? undefined : this[fieldName]);

			if(isUndefined(value) && has(config, 'defaultValue')) {
				value = isFunction(config.defaultValue) ? config.defaultValue() : config.defaultValue;
			}

			if(config.type === Number && isString(value)) {
				value = toNumber(value);
			}

			const fieldIsModel = config.type && has(config.type, 'propertyMap');

			if(fieldIsModel) {
				value = new config.type(isObject(value) && !isArray(value) ? value : {});
			}

			this[fieldName] = value;

			if(!fieldIsModel && config.type && typeof config.type() !== typeof this[fieldName]) {
				throw new TypeError(`Invalid value for field "${fieldName}", expected ${typeof config.type()}, got ${typeof this[fieldName]}`);
			}

			if(!extendedRules[fieldName]) {
				extendedRules[fieldName] = [];
			}

			//Adjusts so that validation functions are called with value, label, and context.
			this.$rules[fieldName] = [
				(value) => {
					for(const rule of [...config.rules, ...extendedRules[fieldName]]) {
						const res = rule({value, fieldName, label: config.label, context: this});

						if(res !== true) {
							return res;
						}
					}

					return true;
				},
			];
		});

		if(isNew) {
			this._originalAttributes = pick(this, ...keys(this.constructor.propertyMap));
		}
	}

	isModified(fieldName) {
		return fieldName
			? includes(this._changedAttributes, fieldName)
			: this._changedAttributes.length > 0;
	}

	clearModifiedState() {
		this._changedAttributes  = [];
	}

	getModifiedFieldsAsJSON() {
		const result = {};

		for(const fieldName of this._changedAttributes) {
			result[fieldName] = cloneDeep(this[fieldName]);
		}

		return result;
	}

	static get propertyMap() {
		return {};
	}

	get fields() {
		return keys(this.constructor.propertyMap);
	}

	toJSON() {
		const data = pick(this, this.fields);
		const dateFields = compact(map(this.constructor.propertyMap, (config, field) => config.isDateField ? field : null));

		for(const field of this.fields) {
			if(includes(dateFields, field)) {
				let value = data[field];

				if(value === '') {
					data[field] = '1899-12-30';
				} else {
					try {
						if(
							value &&
							value.length !== 'YYYY-MM-DD'.length &&
							isValidDate(formatDate(value))
						) {
							value = formatDate(value);
						}
					} catch {}

					data[field] = value;
				}
			}

			if(isArray(data[field])) {
				data[field] = map(data[field], (item) => item.toJSON ? item.toJSON() : item);
			}

			if(field === 'PersNr') {
				data[field] = data[field].replaceAll('-', '');
			}
		}

		return data;
	}

	clear() {
		throw new Error('Do not use model.clear! Use $refs.form.reset() instead');
	}
}

export default BaseModel;
