import _      from 'lodash';
import moment from 'moment';

export default (value) => {
	const currentDate        = new Date();
	const currentYear        = currentDate.getFullYear();
	const currentMonthPadded = _.padStart(currentDate.getMonth() + 1, 2, '0');

	let val      = _.split(_.replace(_.replace(value, /[^\d-]/g, ''), /-|\s/g, ''), '');
	const length = val.length;

	let YY;
	let MM;
	let DD;

	switch (length) {
		case 1: {
			return `${currentYear}-${currentMonthPadded}-0${val[0]}`
		}
		case 2: {
			return `${currentYear}-${currentMonthPadded}-${val[0]}${val[1]}`
		}
		case 3: {
			return `${currentYear}-0${val[0]}-${val[1]}${val[2]}`;
		}
		case 4: {
			if(parseInt(`${val[0]}${val[1]}`, 10) >= 12) {
				return `${currentYear.toString().slice(0, 2)}${val[0]}${val[1]}-${val[2]}${val[3]}-01`;
			}

			return `${currentYear}-${val[0]}${val[1]}-${val[2]}${val[3]}`;
		}
		case 5: {
			YY = `20${val[0]}${val[1]}`;
			if(parseInt(val[2] + val[3]) > 12) {
				YY = `200${val[0]}`;
				MM = `${val[1]}${val[2]}`;
				DD = val[3] + val[4];
			} else {
				YY = `20${val[0]}${val[1]}`;
				MM = `${val[2]}${val[3]}`;
				DD = `0${val[4]}`;
			}

			break;
		}
		case 6: {
			YY = `20${val[0]}${val[1]}`;
			MM = `${val[2]}${val[3]}`;
			DD = `${val[4]}${val[5]}`;

			break;
		}
		case 8: {
			YY = `${val[0]}${val[1]}${val[2]}${val[3]}`;
			MM = `${val[4]}${val[5]}`;
			DD = `${val[6]}${val[7]}`;
		}
	}
	if(length === 6 || length === 7 || length === 5 || length === 8) {
		const currentYear = moment().year();

		if(parseInt(YY, 10) > currentYear + 10) {
			YY = (parseInt(YY, 10) - 100).toString();
		}

		val = `${YY}-${MM}-${DD}`;
	} else {
		return value;
	}

	return val;
};
