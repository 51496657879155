import moment from 'moment';

const isValidDate = (value, {deferValidationUntilFormatted = false} = {}) => {
	if(!('string' === typeof value)) {
		return false;
	}

	//Only validate after value has been formatted
	if(deferValidationUntilFormatted && value.length !== 'YYYY-MM-DD'.length) {
		return true;
	}

	if(
		!Date.parse(value) ||
		!moment(value).isValid()
	) {
		return false;
	}

	return true;
};

export default isValidDate;
